<template>
  <div id="scenario-list-page">
    <section class="for-rt">
      <div class="container">
        <div class="room-title">News List</div>
      </div>
    </section>

    <section class="show-item">
      <div class="container">
        <form @submit.prevent="search">
          <div class="filter-row">
            <div class="ft-filter">
              <!-- Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list -->
              Showing All Results
            </div>
            <div class="form-search">
              <input type="text" class="form-control" placeholder="Search..." v-model="filter">
              <button type="submit" class="btn btn-search"><span class="icon-ico-search"></span></button>
            </div>
          </div>
        </form>
      </div>
    </section>

    <section class="list-news">
      <div class="container">
        <div class="row">
          <template v-for="(berita, index) in news">
          <div class="col-lg-4 col-sm-6" :key="index">
            <div class="box-cs mb30">
              <div class="img-room">
                <figure>
                  <router-link :to="`/news/${berita.slug}`">
                    <img :src="berita.banner_url" class="img-fluid w-100">
                  </router-link>
                </figure>
              </div>
              <div class="box-white">
                <h4 class="cs-head mb-2">{{ berita.title }}</h4>
                <div class="db-news-date">{{ berita.date }}</div>

                <p class="mb40 height-60">{{ berita.description | short_description(110) }}</p>

                <router-link :to="`/news/${berita.slug}`" class="link-service">
                  View Details &nbsp;<span class="icon-chevron-right"></span>
                </router-link>
              </div>
            </div>
          </div>
          </template>
        </div>
        <!-- <div class="container">
        <div class="d-flex justify-content-center mt-5">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
          </ul>
        </div>
      </div> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "NewsList",
  data() {
    return {
      totalRows: 0,
      currentPage: 1,
      perPage: 9,
      filter: null,
      sortBy: "created_at",
      sortDesc: false,
      news: [],
    }
  },
  watch: {
    currentPage(){
      this.fetchScenario()
    }
  },
  computed: {
    startRow() {
      if (this.currentPage == 1) {
        return 1
      } else {
        return (this.perPage * (this.currentPage - 1 )) + 1
      }
    },
    untilRow() {
      if ((this.currentPage * this.perPage) <= this.totalRows) {
        return this.currentPage * this.perPage
      } else {
        return this.totalRows
      }
    }
  },
  mounted() {
    if (this.$route.query.search !== undefined) {
      this.filter = this.$route.query.search
    }
    this.fetchNews()
  },
  methods: {
    fetchNews() {
      this.$axios.get(`/master/news/view_all`, {
        params: {
          page: this.currentPage,
          per_page: this.perPage,
          search: this.filter,
          sortBy: this.sortBy,
          sortType: (this.sortDesc)? 'asc' : 'desc'
        }
      }).then(response => {
        this.news = response.data.data.data
        this.totalRows = response.data.data.meta.total
      })
    },
    search() {
      this.page = 1
      this.fetchNews()
    },
  }
}
</script>

<style scoped>
.box-white {
  height: 230px !important;
}
.height-60 {
  height: 60px !important;
}
</style>
